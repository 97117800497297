import React from "react";
import PropTypes from "prop-types";
import { IGatsbyImageData } from "gatsby-plugin-image";
import Link from "../../common/Link";
import {
  Blurb,
  Container,
  Description,
  Name,
  Sting,
  Thumbnail,
} from "./styles";
import { IPositional } from "../../../types/global";

interface IProps extends IPositional {
  description: string;
  name: string;
  sting: string;
  thumbnail: IGatsbyImageData;
  url: string;
}
function ProjectPreview({
  description,
  name,
  position,
  sting,
  thumbnail,
  url,
}: IProps): JSX.Element {
  return (
    <Container position={position}>
      <Thumbnail
        position={position}
        rotation={position % 2 === 0 ? -4 : 4}
        image={thumbnail}
        alt={`Screenshot of ${name}`}
      />
      <Description position={position}>
        <Name position={position}>
          <Link to={url}>{name}</Link>
        </Name>
        <Sting>{sting}</Sting>
        <Blurb position={position}>{description}</Blurb>
      </Description>
    </Container>
  );
}

ProjectPreview.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  sting: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({}).isRequired,
  url: PropTypes.string.isRequired,
};

export default ProjectPreview;
