import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { IPositional } from "../../../types/global";

export const Container = styled.div<IPositional>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media (min-width: 768px) {
    display: grid;
    grid-column: ${({ position }) => (position % 2 === 0 ? 2 : 1)} / span 5;
  }

  @media (min-width: 1300px) {
    grid-template-areas: "thumb description";
    grid-column-gap: var(--size-5);
  }
`;

interface IThumbnail extends IPositional {
  rotation: number;
  width?: number;
}
export const Thumbnail = styled(GatsbyImage)<IThumbnail>`
  border: 5px solid white;
  border-radius: var(--size-3);
  box-shadow: var(--shadow-lg);
  height: auto;
  max-width: min(100%, 400px);
  transform: rotate(${({ rotation }) => `${rotation}deg`});
  width: calc(${({ width }) => `400px * ${width || 1}`});
  z-index: -1;

  @media (min-width: 425px) {
    margin: ${({ position }) =>
      position % 2 === 0 ? "0 0 0 auto" : "0 auto 0 0"};
  }

  @media (min-width: 768px) {
    margin-left: ${({ position }) =>
      position % 2 === 0 ? "auto" : "var(--size-8)"};
  }

  @media (min-width: 1300px) {
    margin-left: auto;
  }
`;

export const Description = styled.div<IPositional>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: var(--size-3);
  max-width: 65ch;
  text-align: ${({ position }) => (position % 2 === 0 ? "right" : "left")};

  @media (min-width: 1024px) {
    margin: ${({ position }) =>
      position % 2 === 0 ? "0 var(--size-3) 0 auto" : "0 auto 0 var(--size-3)"};
  }

  @media (min-width: 1300px) {
    grid-area: description;
    text-align: left;
    margin: 0 auto 0 var(--size-3);
  }
`;

export const Name = styled.h2<IPositional>`
  font-size: var(--font-size-md);
  margin-top: var(--size-2);
  margin-bottom: 0;
  white-space: nowrap;

  @media (min-width: 1024px) {
    margin-top: var(--size-4);
    font-size: var(--font-size-lg);
  }

  @media (min-width: 1300px) {
    font-size: var(--font-size-x2l);
    margin-top: 0;
    margin-left: ${({ position }) => (position === 1 ? "var(--size-6)" : 0)};
  }
`;

export const Sting = styled.h3`
  font-size: var(--font-size-xs);
  font-weight: bold;
  margin: var(--size-2) 0;

  @media (min-width: 1024px) {
    font-size: var(--font-size-sm);
  }

  @media (min-width: 1300px) {
    font-size: var(--font-size-md);
    margin: var(--size-4) 0 var(--size-4) var(--size-4);
  }
`;

export const Blurb = styled.div<IPositional>`
  hyphens: auto;
  text-align: justify;

  @media (min-width: 1024px) {
    font-size: var(--font-size-sm);
    hyphens: none;
    text-align: left;
  }

  @media (min-width: 1300px) {
    font-size: var(--font-size-md);
    margin-left: ${({ position }) => (position !== 1 ? "var(--size-6)" : 0)};
  }
`;
