export type SkillLevel = 1 | 2 | 3;
export type SkillCategory = "tech" | "hard" | "soft";

export interface ISkill {
  name: string;
  level?: SkillLevel;
  category: SkillCategory;
}

type SkillCategories = {
  [category in SkillCategory]: string;
};
export const categories: SkillCategories = {
  tech: `Specific technologies`,
  hard: `My technical capabilities, unconnected to specific technologies`,
  soft: `My personality, work ethic, and attitude`,
};

type SkillLevels = {
  [level in SkillLevel]: string;
};
export const skillLevels: SkillLevels = {
  1: `I am familiar with the technology and have completed at least one project with it`,
  2: `I am comfortable with the technology, use it frequently, or know advanced features/patterns`,
  3: `I am proficient with the technology and use it daily on production systems`,
};

export const skills: ISkill[] = [
  { name: "Kubernetes", level: 3, category: "tech" },
  { name: "Docker", level: 3, category: "tech" },
  { name: "Javascript", level: 3, category: "tech" },
  { name: "PHP", level: 3, category: "tech" },
  { name: "Laravel", level: 3, category: "tech" },
  { name: "HTML", level: 3, category: "tech" },
  { name: "CSS", level: 3, category: "tech" },
  { name: "Redis", level: 3, category: "tech" },
  { name: "Git", level: 3, category: "tech" },
  { name: "SQL", level: 3, category: "tech" },
  { name: "Nginx", level: 2, category: "tech" },
  { name: "React", level: 2, category: "tech" },
  { name: "Jenkins", level: 2, category: "tech" },
  { name: "Typescript", level: 2, category: "tech" },
  { name: "NodeJS", level: 2, category: "tech" },
  { name: "ElasticSearch", level: 2, category: "tech" },
  { name: "MongoDB", level: 2, category: "tech" },
  { name: "Gatsby", level: 1, category: "tech" },
  { name: "NextJS", level: 1, category: "tech" },
  { name: "Svelte", level: 1, category: "tech" },
  { name: "Python", level: 1, category: "tech" },
  { name: "HAProxy", level: 1, category: "tech" },
  { name: "GraphQL", level: 1, category: "tech" },
  { name: "CLI", category: "hard" },
  { name: "Testing", category: "hard" },
  { name: "Automation", category: "hard" },
  { name: "API design", category: "hard" },
  { name: "Scaling", category: "hard" },
  { name: "Project management", category: "hard" },
  { name: "Architecture", category: "hard" },
  { name: "Optimization", category: "hard" },
  { name: "DB management", category: "hard" },
  { name: "Technical writing", category: "hard" },
  { name: "Infrastructure monitoring", category: "hard" },
  { name: "UI design", category: "hard" },
  { name: "Tooling", category: "hard" },
  { name: "Security", category: "hard" },
  { name: "Reliable", category: "soft" },
  { name: "Self-supervising", category: "soft" },
  { name: "Clear in communication", category: "soft" },
  { name: "Friendly and respectful", category: "soft" },
  { name: "Focused on the long-term", category: "soft" },
  { name: "Flexibile", category: "soft" },
  { name: "Caring towards my colleagues", category: "soft" },
  { name: "Organized", category: "soft" },
  { name: "Against zero-sum thinking", category: "soft" },
  { name: "Resourceful", category: "soft" },
  { name: "Comfortable with uncertainty", category: "soft" },
  { name: "Perseverant", category: "soft" },
  { name: "Good at working alone & in teams", category: "soft" },
];
