import React, { MouseEventHandler } from "react";
import { SkillCategory } from "../../../data/skills";
import { HTMLElementEvent } from "../../../types/global";
import { Container, FilterButton, FilterGroup } from "./styles";

interface IProps {
  onFilterChange: (x: SkillCategory) => void;
}

interface IState {
  selected: SkillCategory;
}

class SkillFilter extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selected: "tech",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e: HTMLElementEvent<HTMLButtonElement>): void {
    if (!e.target) return;
    e.target.blur();
    const newCategory = e.target.dataset.category as SkillCategory;
    this.setState({ selected: newCategory });
    const { onFilterChange } = this.props;
    onFilterChange(newCategory);
  }

  render(): JSX.Element {
    const categories = ["tech", "hard", "soft"];
    const { selected } = this.state;
    return (
      <Container>
        <FilterGroup>
          {categories.map((category) => (
            <FilterButton
              data-category={category}
              key={category}
              className={selected === category ? "is-selected" : ""}
              type="button"
              onClick={this.handleClick as unknown as MouseEventHandler}
            >
              {category}
            </FilterButton>
          ))}
        </FilterGroup>
      </Container>
    );
  }
}

export default SkillFilter;
