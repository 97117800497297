import styled from "styled-components";
import { SkillCategory } from "../../../data/skills";

export const Container = styled.div`
  display: flex;
  margin: var(--size-2);
`;

export const Name = styled.div``;

export const Level = styled.div`
  font-weight: 600;
  margin-right: var(--size-1);
`;

interface ICard {
  category: SkillCategory;
}
export const Card = styled.div<ICard>`
  border: 1px solid var(--primary-600);
  border-radius: var(--size-5);
  display: inline-flex;
  padding: var(--size-1) var(--size-3) var(--size-1) var(--size-2);
  transition: box-shadow var(--speed-md) ease-out var(--speed-md);

  &.no-level {
    padding: var(--size-1) var(--size-2);
  }

  &.is-visible.level-3 {
    box-shadow: var(--shadow-md);
  }

  &.is-visible.level-2 {
    box-shadow: var(--shadow-xs);
  }

  &.level-3 ${Name} {
    font-size: var(--font-size-sm);
  }

  &.level-2 ${Name} {
    font-size: var(--font-size-xs);
  }

  &.level-2 ${Level} {
    font-size: var(--font-size-x2s);
  }

  &.level-1 ${Level} {
    font-size: var(--font-size-x3s);
  }
`;
