import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Card, Container, Level, Name } from "./styles";
import { SkillCategory, SkillLevel } from "../../../data/skills";

interface IProps {
  level: SkillLevel | undefined;
  name: string;
  category: SkillCategory;
}

interface IState {
  isVisible: boolean;
}

class Skill extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isVisible: false,
    };

    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  handleVisibilityChange(isVisible: boolean): void {
    this.setState({ isVisible });
  }

  render(): JSX.Element {
    const { level, name, category } = this.props;
    const { isVisible } = this.state;

    let classes = isVisible ? "is-visible " : " ";
    switch (level) {
      case 3:
        classes += "level-3";
        break;
      case 2:
        classes += "level-2";
        break;
      case 1:
        classes += "level-1";
        break;
      default:
        classes += "no-level";
    }

    return (
      <VisibilitySensor onChange={this.handleVisibilityChange}>
        <Container>
          <Card category={category} className={classes}>
            {level ? <Level>{level}</Level> : ""}
            <Name>{name}</Name>
          </Card>
        </Container>
      </VisibilitySensor>
    );
  }
}

export default Skill;
