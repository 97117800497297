import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, List, Preamble } from "./styles";
import Separator from "../../common/Separator";
import ProjectPreview from "../ProjectPreview";
import projects from "../../../data/projects";

function ProjectList(): JSX.Element {
  const thumbnails = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "thumbnails" } }
        sort: { fields: base }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
        }
      }
    }
  `);

  const [learnbrite, gaze, monty] = projects;
  // TODO: Add & show tags
  return (
    <Container>
      <Preamble>Here&apos;s a few projects that describe me well!</Preamble>
      <List>
        <ProjectPreview
          description={learnbrite.description}
          key={learnbrite.id}
          name={learnbrite.name}
          position={learnbrite.id}
          sting={learnbrite.sting}
          thumbnail={
            thumbnails.allFile.edges[learnbrite.id].node.childImageSharp
              .gatsbyImageData
          }
          url={learnbrite.url}
        />
        <Separator />
        <ProjectPreview
          description={gaze.description}
          key={gaze.id}
          name={gaze.name}
          position={gaze.id}
          sting={gaze.sting}
          thumbnail={
            thumbnails.allFile.edges[gaze.id].node.childImageSharp
              .gatsbyImageData
          }
          url={gaze.url}
        />
        <Separator isRotated />
        <ProjectPreview
          description={monty.description}
          key={monty.id}
          name={monty.name}
          position={monty.id}
          sting={monty.sting}
          thumbnail={
            thumbnails.allFile.edges[monty.id].node.childImageSharp
              .gatsbyImageData
          }
          url={monty.url}
        />
      </List>
    </Container>
  );
}

export default ProjectList;
