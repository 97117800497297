import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import Nav from "../components/common/Nav";
import Intro from "../components/home/Intro";
import {
  Grid,
  SquiggleSeparator,
  CirclesSeparator,
  TicTacToeSeparator,
} from "../components/common/Grid";
import ProjectList from "../components/home/ProjectList";
import SkillList from "../components/home/SkillList";
import Footer from "../components/common/Footer";
import Contact from "../components/home/Contact";

const Landing = () => {
  const photo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "christian/duotone.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Christian Fratta's portfolio"
        description="Welcome! This is a collection of projects/skills/info that describe me pretty well as a person and as a professional"
      />
      <Nav />
      <Grid>
        <Intro
          photo={photo.file.childImageSharp.gatsbyImageData}
          blurb={{
            __html: `
          <span>I&apos;m a full-stack developer</span>
          <span>
            who loves <b>data</b> & <b>integrity</b>
          </span>`,
          }}
          greeting={{
            __html: `<h1>Hello!</h1>
          <h1>I&apos;m Christian!</h1>`,
          }}
        />
        <SquiggleSeparator>
          <div />
          <div />
        </SquiggleSeparator>
        <ProjectList />
        <CirclesSeparator>
          <div />
          <div />
        </CirclesSeparator>
        <SkillList />
        <TicTacToeSeparator>
          <div />
          <div />
        </TicTacToeSeparator>
        <Contact />
      </Grid>
      <Footer />
    </Layout>
  );
};

export default Landing;
