import React from "react";
import { Container, Explainer, List, Preamble, TechLevels } from "./styles";
import Skill from "../Skill";
import SkillFilter from "../SkillFilter";
import {
  categories,
  skills,
  skillLevels,
  SkillCategory,
} from "../../../data/skills";

interface IProps {}

interface IState {
  listTransition: "in" | "out";
  filter: SkillCategory;
}

class SkillList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { filter: "tech", listTransition: "in" };

    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange(newValue: SkillCategory): void {
    this.setState({ listTransition: "out" });

    setTimeout(() => {
      this.setState({ filter: newValue, listTransition: "in" });
    }, 300);
  }

  render(): JSX.Element {
    const { filter, listTransition } = this.state;
    const selectedSkills = skills.filter((skill) => skill.category === filter);

    return (
      <Container>
        <Preamble>Here are some things I can do</Preamble>
        <SkillFilter onFilterChange={this.handleFilterChange} />
        <Explainer className={`fade-${listTransition}`}>
          {categories[filter]}
          {filter === "tech" && (
            <TechLevels>
              <li>
                <b>3</b> - {skillLevels[3]}
              </li>
              <li>
                <b>2</b> - {skillLevels[2]}
              </li>
              <li>
                <b>1</b> - {skillLevels[1]}
              </li>
            </TechLevels>
          )}
        </Explainer>
        <List className={`fade-${listTransition}`}>
          {selectedSkills.map(({ category, level, name }) => (
            <Skill key={name} level={level} name={name} category={category} />
          ))}
        </List>
      </Container>
    );
  }
}

export default SkillList;
