import styled from "styled-components";

export const Container = styled.div`
  justify-content: center;
  display: flex;
  padding: var(--size-2) 0;

  @media (min-width: 1300px) {
    grid-area: filters;
  }
`;

export const FilterGroup = styled.div`
  align-items: center;
  display: flex;
  padding: var(--size-2) 0;

  @media (min-width: 1300px) {
    align-items: flex-start;
    flex-direction: column;
    margin-right: auto;
  }
`;

export const FilterButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--primary-600);
  border-radius: var(--size-2);
  box-shadow: var(--shadow-md);
  font-size: var(--font-size-sm);
  margin: var(--size-2);
  padding: var(--size-1) var(--size-2);
  text-transform: capitalize;
  transition-property: color, background-color, box-shadow;
  transition-duration: var(--speed-fast);
  transition-timing-function: ease-out;

  &.is-selected {
    background-color: var(--primary-600);
    color: white;
    box-shadow: inset 0 1px 3px hsla(0deg 0% 0% / 12%),
      0 1px 2px hsla(0deg 0% 0% / 24%);
  }

  @media (min-width: 1300px) {
    font-size: var(--font-size-sm);
    width: 100%;
  }
`;
