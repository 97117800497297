import styled from "styled-components";

export const Container = styled.div`
  grid-column: 2 / -2;
  padding: var(--size-7) 0 var(--size-8) 0;

  @media (min-width: 1024px) {
    padding: var(--size-9) 0;
  }

  @media (min-width: 1024px) {
    padding-bottom: var(--size-10);
  }
`;

export const Preamble = styled.h1`
  align-items: center;
  font-size: var(--font-size-lg);
  font-weight: 600;
  margin: 0 0 var(--size-7) auto;
  max-width: 20ch;
  text-align: right;
  width: 100%;

  @media (min-width: 768px) {
    font-size: var(--font-size-xl);
  }

  @media (min-width: 1024px) {
    font-size: var(--font-size-x2l);
    margin-left: auto;
    margin-right: var(--size-6);
  }

  @media (min-width: 1300px) {
    font-size: var(--font-size-x3l);
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: grid;
    grid-column-gap: var(--size-5);
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const Separator = styled.img`
  height: auto;
  margin: var(--size-5) auto;
  width: 60px;
  z-index: -1;

  &:nth-child(2) {
    transform: rotate(30deg);
  }

  &:nth-child(4) {
    transform: rotate(-30deg) scaleX(-1);
  }

  @media (min-width: 768px) {
    grid-column: 1/-1;
    margin: var(--size-8) auto var(--size-7) auto;
    width: 100px;
  }
`;
