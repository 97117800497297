import styled from "styled-components";

export const Container = styled.div`
  grid-column: 2 / -2;
  padding: var(--size-8) 0;

  @media (min-width: 1300px) {
    display: grid;
    grid-template-areas:
      ". preamble preamble preamble ."
      ". explainer explainer explainer ."
      ". list list filters ."
      ". list list filters ."
      ". list list filters .";
    padding: var(--size-10) 0;
  }
`;

export const Explainer = styled.div`
  color: var(--grey-500);
  font-size: var(--font-size-xs);
  grid-area: explainer;
  height: fit-content;
  hyphens: auto;
  margin: 0 auto;
  max-width: 70ch;
  padding: var(--size-2) 0;
  text-align: center;
  transition: opacity var(--speed-fast) ease-out;

  &.fade-in {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
  }

  @media (min-width: 1024px) {
    margin-bottom: var(--size-2);
    font-size: var(--font-size-sm);
  }

  @media (min-width: 1300px) {
    margin: var(--size-2) auto;
    font-size: var(--font-size-md);
  }

  @media (min-width: 1440px) {
    margin: var(--size-6) auto var(--size-5) auto;
  }
`;

export const TechLevels = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  padding: 0;
  text-align: left;

  & li {
    padding: var(--size-2) 0;
  }
`;

export const List = styled.div`
  align-content: flex-start;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-area: list;
  justify-content: center;
  padding-top: var(--size-3);
  transition: opacity var(--speed-fast) ease-out;

  &.fade-in {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
  }

  @media (min-width: 1300px) {
    justify-self: flex-end;
    max-width: 80ch;
    padding-right: var(--size-5);
  }
`;

export const Preamble = styled.h1`
  text-align: center;
  font-size: var(--font-size-lg);
  font-weight: 600;
  grid-area: preamble;
  margin: 0;

  @media (min-width: 768px) {
    font-size: var(--font-size-xl);
  }

  @media (min-width: 1024px) {
    font-size: var(--font-size-x2l);
  }

  @media (min-width: 1300px) {
    font-size: var(--font-size-x3l);
  }
`;
